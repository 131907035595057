import Text from "../../Shared/Basic/Text";

function Certifications({ text }) {

    return (

        <div className={`row j-space-around col-12 mb-4 mt-150`}>

            {text?.pics?.map((info, i) => (

                <div className={`display-f j-space-between col col-4-xxl col-4-xl col-4-lg col-10-md col-10-sm col-10-xs reverse-responsive`}
                    key={i}>

                    <div className={`pt-150 pb-150 mb-2 col center`}>

                        <div className={`display-f j-center ai-center responsive-logo-containers `}>

                            <img src={info.pic}
                                className={`pt-3 responsive-logos ${info.addCss ? info.addCss : ""}`}
                                alt={`Studiel related ${info.alt ? info.alt : info.text}`} />

                        </div>

                    </div>

                    {!(info.since || info.text) ? null :

                        <div className={`display-f col j-center`}>

                            <Text css={`t-center fw-b font-ml`}>

                                {info.since}

                            </Text>

                            <Text css={`pl-3 pr-3 pt-1 t-center fw-b`}>

                                {info.text}

                            </Text>

                        </div>
                    }

                </div>

            ))}

        </div>

    );
}

export default Certifications;