// PICS
// fallbacks for vidz
import fallebackStudielFirst from "./assets/Videos/fallback-studiel-frist-page.webp";

// Job Pics
import prodmetiergroupe from "./assets/Image/prodmetiergroupe.webp";
import mecametiergroupe from "./assets/Image/mecametiergroupe.png";
import infometiergroupe from "./assets/Image/infometiergroupe.webp";

// SSERP 
import first from "./assets/Logos/heart.png";
import second from "./assets/Logos/mind.png";
import third from "./assets/Logos/leaf.png";
import forth from "./assets/Logos/orga.png";
import fifth from "./assets/Logos/up.png";
import sixt from "./assets/Logos/check.png";

// RSE - ETHIQUE - DIVERSITY
import office1 from "./assets/Image/officepart1.jpg";
import office2 from "./assets/Image/officepart2.jpg";
import office3 from "./assets/Image/officepart3.jpg";

// SYSTEMES
import circuitSyst1 from "./assets/Image/circuit-syst-1.webp";
import circuitSyst2 from "./assets/Image/circuit-syst-2.webp";
import circuitSyst3 from "./assets/Image/circuit-syst-3.webp";
import logosElec from "./assets/Logos/logoselec.JPG";

// PROD
import prodmetiers from "./assets/Image/souder.webp";
import produits from "./assets/Image/produits.webp";
import produitsSouder from "./assets/Image/produitssouder.webp";

// IT
import info2 from "./assets/Image/info2.webp";
import itjobs from "./assets/Image/itjobs1.webp";
import itmetier from './assets/Image/itmetier.JPG';

// MECA
import etapesvieProduit from "./assets/Image/etapes-vie-produit.png";

// Bands pics (yhea the same for everyone for now)

// values pics
import coffee from "./assets/Image/coffee.webp";
import parc from "./assets/Image/parc.webp";

// OFFERS (single)
import offerfirst from "./assets/Image/offerfirst.webp";
import joinus from "./assets/Image/joinus.png";

// CONTACT
import contact from "./assets/Image/contact.webp";

// OLD TIMELINE
// import timeline from "./assets/Image/timeline.png";

// LOGOS (-general-)
import studielOldLogo from "./assets/Logos/studold.png";
import studiel from "./assets/Logos/studiel.svg";
import studielinwhite from './assets/Logos/studielwhite.png';
import itLogo from "./assets/Logos/info.png";
import mecanLogo from "./assets/Logos/mecan.png";
import prodLogo from './assets/Logos/prod.png';
import stystLogo from "./assets/Logos/systeme.png";
import consultingLogo from "./assets/Logos/consulting.png";
import lightbulbstakes from "./assets/Logos/ampoule.png";
import whiteprod from "./assets/Logos/whiteprod.png";
import whiteit from './assets/Logos/whiteit.png';
import whitesyst from './assets/Logos/whiteelecro.png';
import whitemeca from './assets/Logos/whitemeca.png';

// STUDIEL S'ENGAGE LOGOS
import logoCPL from "./assets/Logos/LABEL_CPL_TRANSPARENT.png";
import logoCPLNice from "./assets/Logos/LABEL_CPL_NICE_TRANSPARENT.png"
import logocharte from "./assets/Logos/LOGO_Charte_diversite.png";

// QUALITé - CERTIFICATIONS
import Iso9001Pic from "./assets/Logos/ISO90012015 bureau_veritas_studiel-group.png";
import mase from "./assets/Logos/Logo_Mase_Studiel_Website.jpg";
import quali from './assets/Logos/Macaron_qualianor_couleur_126X85.jpg';

// GROUPE EXPERTISES BGs
import electroBg from "./assets/Background/electrobg.png";
import infoBg from "./assets/Background/infoBg.png";
import prodBg from "./assets/Background/prodBg.png";
import mecaBg from "./assets/Background/mecaBg.png";
import otherBg from "./assets/Background/otherbg.png";

// Management Systemes (pour tablette et mobiles)
import managementSyst from "./assets/Image/managementSyst.png";

// Studiel s'engage
import engageMega from "./assets/Logos/sengage-megaphone.png";
import engageHands from "./assets/Logos/sengage-main.png";
import engageHold from "./assets/Logos/sengage-hold.png";

// Bureau études et conseils
import groupebe from "./assets/Image/groupe-be.png";
import groupeconseil from "./assets/Image/groupe-conseil.png";

// LOGO VALUES
import puzzle from "./assets/Logos/puzzle.png";
import strategy from "./assets/Logos/strategy.png";

//// Logo recrutement
// projets passionants
import brain from "./assets/Logos/brain.png";
//tremplin
import developpement from "./assets/Logos/development.png";
//equipes
import hight from "./assets/Logos/high.png";
// double activité
import machine from "./assets/Logos/machine.png";

// Logos only (without text)
import mecalogoonly from './assets/Logos/red.png';
import systlogonly from "./assets/Logos/blue.png";
import prodlogoonly from "./assets/Logos/green.png";
import itlogoonly from "./assets/Logos/pink.png";
import studiellogonly from "./assets/Logos/orange.png";
// Burger menu
import bugerMenu from "./assets/Logos/burgermenu.svg";

// Direction
import david from "./assets/direction/david.png";
import nicolas from "./assets/direction/nicolas.png";
import jeanpierre from "./assets/direction/jeanpierre.png";

// Flags Logo
import fr from "./assets/Logos/fr.svg";
import gb from "./assets/Logos/gb.svg";

// égalté homme femme 
import egality from "./assets/Image/egalitehommfemme.jpg"

export {
    fallebackStudielFirst,
    prodmetiergroupe,
    mecametiergroupe,
    infometiergroupe,
    first,
    second,
    third,
    forth,
    fifth,
    sixt,
    office1,
    office2,
    office3,
    circuitSyst1,
    circuitSyst2,
    circuitSyst3,
    logosElec,
    prodmetiers,
    produits,
    produitsSouder,
    info2,
    itjobs,
    itmetier,
    etapesvieProduit,
    coffee,
    parc,
    offerfirst,
    joinus,
    contact,
    studielOldLogo,
    studiel,
    studielinwhite,
    itLogo,
    mecanLogo,
    prodLogo,
    stystLogo,
    consultingLogo,
    lightbulbstakes,
    whiteprod,
    whiteit,
    whitesyst,
    whitemeca,
    logoCPL,
    logocharte,
    Iso9001Pic,
    mase,
    quali,
    electroBg,
    infoBg,
    prodBg,
    mecaBg,
    otherBg,
    managementSyst,
    engageMega,
    engageHands,
    engageHold,
    groupebe,
    groupeconseil,
    puzzle,
    strategy,
    brain,
    developpement,
    hight,
    machine,
    mecalogoonly,
    systlogonly,
    prodlogoonly,
    itlogoonly,
    studiellogonly,
    bugerMenu,
    david,
    nicolas,
    jeanpierre,
    fr,
    gb,
    egality,
    logoCPLNice
};

// Adress HQ for everywhere
export const adress = `
455 Promenade des Anglais, 
Immeuble Terrazzo Bâtiment 
06200 Nice
`
// Phone HQ for everywhere
export const telephone = `+33 4 92 02 45 45`

export const implantationNav = "implantation-nav"

// Recrutement mail for everywhere
export const recrutementEmail = 'recrutement@studielgroup.com';

// Contact mail for everywhere
export const email = `contact@studielgroup.com`

// MAP INFO for the front page (made mor sence for me to put'em here)
export const mapFrance = [
    {
        "title": "STUDIEL Ile-de-france",
        "address": `
Chez Emergence 
121 rue d’Aguesseau, 
92100 Boulogne Billancourt`,
        "maps": "https://www.google.com/maps/place/Studiel+Ile+de+France./@48.7867947,2.0533764,17z/data=!3m1!4b1!4m6!3m5!1s0x47e6872e6faa3d1d:0xad4944ceedf2e3e1!8m2!3d48.7867947!4d2.0559513!16s%2Fg%2F1tcy3mgw",
        "telephone": "+33 (0)6 45 61 12 90",
        "pos": { "top": "15%", "left": "45%" },
    },
    {
        "title": "STUDIEL Toulouse \nAgence et Bureau d’Etude",
        "address": `
4, rue Paul Mesplé
West Park Zone Basso Cambo
31100 Toulouse`,
        "maps": "https://www.google.com/maps/place/Studiel/@43.567585,1.38283,17z/data=!3m1!4b1!4m6!3m5!1s0x12aeba15f334329b:0x93fc116aa705b980!8m2!3d43.567585!4d1.3854049!16s%2Fg%2F1tfn52hr",
        "telephone": "+33 (0)6 37 32 81 99",
        "pos": { "bottom": "24%", "left": "35%" },
    },
    {
        "title": "STUDIEL Bordeaux",
        "address": `
Building Clément,         
1 Avenue Neil Armstrong
33700 Mérignac`,
        "maps": "https://www.google.com/maps/place/19+All.+des+Acacias,+33700+M%C3%A9rignac/@44.8429393,-0.6834515,17z/data=!3m1!4b1!4m5!3m4!1s0xd54da03ea5ad19f:0x706cc3b2a58817be!8m2!3d44.8429393!4d-0.6808766",
        "telephone": "+33 (0)6 75 91 91 79",
        "pos": { "bottom": "30%", "left": "17%" },
    },
    {
        "title": "STUDIEL Aix en Provence",
        "address": `
31, Parc du Golf CS90519
350 avenue JRGG de la Lauzière
13593 Aix en Provence Cedex3`,
        "telephone": "+33 (0)6 71 04 58 41",
        "maps": "https://www.google.com/maps/place/Regus+-+Aix+Parc+du+Golf/@43.4812088,5.3624296,17z/data=!3m1!4b1!4m6!3m5!1s0x12c9ed100b1f2833:0xff0351db2dbc2813!8m2!3d43.4812088!4d5.3650045!16s%2Fg%2F1vl914pq",
        "pos": { "bottom": "23%", "right": "30%" },
    },
    {
        "title": "STUDIEL Montpellier",
        "address": `
Centre d'Affaires Baya Axess
418, rue du Mas de Verchant
BP12 34935 Montpellier`,
        "maps": "https://www.google.com/maps/search/418,+rue+du+Mas+de+Verchant+BP12+34935+Montpellier/@43.619339,3.9108887,17z/data=!3m1!4b1",
        "telephone": "+33 (0)6 75 91 79 79",
        "pos": { "bottom": "22%", "right": "38%" },
    },
    {
        "title": "STUDIEL Sophia-Antipolis \nBureaux d’études et Agence",
        "address": `
15 Traverse des Brucs 
06560 Valbonne Sophia-Antipolis`,
        "telephone": "+33 (0)4 92 02 45 45",
        "commercial": "+33 (0)6 48 55 27 82",
        "maps": "https://www.google.fr/maps/place/Groupe+STUDIEL/@43.628367,7.1263454,17z/data=!3m1!4b1!4m6!3m5!1s0x12cdd48929697051:0x4bf0d62f10c465c5!8m2!3d43.628367!4d7.1289203!16s%2Fg%2F1tf4_lg3",
        "pos": { "bottom": "20%", "right": "23%" },
    },
    {
        "title": "STUDIEL Monaco Engineering",
        "address": `
Talaria Business Center Le Mercator
7 rue de l'industrie
98000 Monaco`,
        "telephone": "+33 (0)6 48 55 27 82",
        "maps": "https://www.google.com/maps/place/Talaria+Business+Center/@43.7280917,7.4127406,18z/data=!3m1!4b1!4m6!3m5!1s0x12cdc2ec8bd89e3d:0x81813178a9df16bc!8m2!3d43.7280898!4d7.4140281!16s%2Fg%2F11c61jcm71?entry=ttu",
        "pos": { "bottom": "23%", "right": "12%" },
    },
    {
        "title": "STUDIEL Lyon",
        "address": `
93 rue de la Vilette
69003 Lyon`,
        "telephone": "+33 (0)6 75 91 79 79",
        "maps": "https://www.google.com/maps/place/93+Rue+de+la+Villette,+69003+Lyon/@45.7574614,4.859468,17z/data=!3m1!4b1!4m6!3m5!1s0x47f4ea7b67ba9ed9:0x7a74c16545e54a28!8m2!3d45.7574577!4d4.8620429!16s%2Fg%2F11c3q3fv9z",
        "pos": { "bottom": "40%", "right": "27%" },

    },
    {
        "title": "STUDIEL Nantes",
        "address": `
Immeuble Skyline
22 Mail Pablo Picasso
44000 Nantes`,
        "telephone": "+33 (0)6 74 04 85 76",
        "maps": "https://www.google.com/maps/search/IMMEUBLE+SKYLINE+22+MAIL+PABLO+PICASSO+44000+NANTES%2B/@47.215368,-1.5393993,17z/data=!3m1!4b1",
        "pos": { "top": "25%", "left": "15%" }
    },
    {
        "title": "STUDIEL Nice - Siège Social",
        "address": adress,
        "telephone": "+33 (0)4 92 02 45 45",
        "commercial": "+33 (0)6 48 55 27 82",
        "color": "#EE7C00",
        "maps": "https://www.google.fr/maps/place/455+Prom.+des+Anglais,+06200+Nice/@43.6689397,7.2137771,18z/data=!3m1!4b1!4m6!3m5!1s0x12cdd104d211c9ff:0x108db83f9ebbfffa!8m2!3d43.6689397!4d7.2146875!16s%2Fg%2F11bw3ywqnb",
        "pos": { "bottom": "22%", "right": "17%" },
    },
]